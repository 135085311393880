/* eslint-disable */
/* tslint:disable */
/* prettier-ignore */
export const translations = {
    "error.unknown": {
        cs: "Neznámá chyba.",
        en: "Unknown error.",
    },
    "notifications.format.minutes": {
        cs: {
            zero: "minut",
            one: "1 minutu",
            few: "{{count}} minuty",
            other: "{{count}} minut",
        },
        en: "",
    },
    "notifications.format.hours": {
        cs: {
            zero: "hodin",
            one: "1 hodinu",
            few: "{{count}} hodiny",
            other: "{{count}} hodin",
        },
        en: "",
    },
    "_general.minute": {
        cs: {
            zero: "0\xa0minut",
            one: "1\xa0minuta",
            few: "{{count}}\xa0minuty",
            other: "{{count}}\xa0minut",
        },
        en: "",
    },
    "_general.day": {
        cs: {
            zero: "0\xa0dní",
            one: "1\xa0den",
            few: "{{count}}\xa0dny",
            other: "{{count}}\xa0dní",
        },
        en: "",
    },
    "_general.month": {
        cs: {
            zero: "0\xa0měsíců",
            one: "1\xa0měsíc",
            few: "{{count}}\xa0měsíce",
            other: "{{count}}\xa0měsíců",
        },
        en: "",
    },
    "_general.year": {
        cs: {
            zero: "0\xa0let",
            one: "1\xa0rok",
            few: "{{count}}\xa0roky",
            other: "{{count}}\xa0let",
        },
        en: "",
    },
    "_general.from": {
        cs: {
            zero: "z\xa0{{count}}",
            one: "z\xa0{{count}}",
            few: "ze\xa0{{count}}",
            other: "z\xa0{{count}}",
        },
        en: "",
    },
    "recurrence.day": {
        cs: {
            zero: "dní",
            one: "den",
            few: "dny",
            other: "dní",
        },
        en: "",
    },
    "recurrence.month": {
        cs: {
            zero: "měsíců",
            one: "měsíc",
            few: "měsíce",
            other: "měsíců",
        },
        en: "",
    },
    "recurrence.week": {
        cs: {
            zero: "týdnů",
            one: "týden",
            few: "týdny",
            other: "týdnů",
        },
        en: "",
    },
    "recurrence.repeat": {
        cs: {
            zero: "výskytech",
            one: "výskytu",
            few: "výskytech",
            other: "výskytech",
        },
        en: "",
    },
    "booking.minutes": {
        cs: {
            zero: "minut",
            one: "minuta",
            few: "minuty",
            other: "minut",
        },
        en: "",
    },
    "booking.days": {
        cs: {
            zero: "dní",
            one: "den",
            few: "dny",
            other: "dní",
        },
        en: "",
    },
    "booking.hours": {
        cs: {
            zero: "hodin",
            one: "hodinu",
            few: "hodiny",
            other: "hodin",
        },
        en: "",
    },
    "medicalOperation.operation": {
        cs: {
            zero: "V\xa0zápisech už je využito {{count}}\xa0výkonů",
            one: "V\xa0zápisu už je využit {{count}}\xa0výkon",
            few: "V\xa0zápisech už jsou využity {{count}}\xa0výkony",
            other: "V\xa0zápisech už je využito {{count}}\xa0výkonů",
        },
        en: "",
    },
    "medicalOperation.points": {
        cs: {
            zero: "{{count}}\xa0bodů",
            one: "{{count}}\xa0bod",
            few: "{{count}}\xa0body",
            other: "{{count}}\xa0bodů",
        },
        en: "",
    },
    "medicalOperation.selected": {
        cs: {
            zero: "{{count}}\xa0vybraných kódů výkonů",
            one: "{{count}}\xa0vybraný kód výkonu",
            few: "{{count}}\xa0vybrané kódy výkonů",
            other: "{{count}}\xa0vybraných kódů výkonů",
        },
        en: "",
    },
    "medicalOperation.available": {
        cs: {
            zero: "z\xa0{{count}}\xa0dostupných",
            one: "z\xa0{{count}}\xa0dostupného",
            few: "ze\xa0{{count}}\xa0dostupných",
            other: "z\xa0{{count}}\xa0dostupných",
        },
        en: "",
    },
    "medicalOperation.presets": {
        cs: {
            zero: "{{count}}\xa0šablon",
            one: "{{count}}\xa0šablona",
            few: "{{count}}\xa0šablony",
            other: "{{count}}\xa0šablon",
        },
        en: "",
    },
    "medicalOperation.DAY": {
        cs: {
            zero: "",
            one: "za den",
            few: "za\xa0{{count}}\xa0dny",
            other: "za\xa0{{count}}\xa0dní",
        },
        en: "",
    },
    "medicalOperation.WEEK": {
        cs: {
            zero: "",
            one: "za týden",
            few: "za\xa0{{count}}\xa0týdny",
            other: "za\xa0{{count}}\xa0týdnů",
        },
        en: "",
    },
    "medicalOperation.MONTH": {
        cs: {
            zero: "",
            one: "za měsíc",
            few: "za\xa0{{count}}\xa0měsíce",
            other: "za\xa0{{count}}\xa0měsícůů",
        },
        en: "",
    },
    "medicalOperation.QUARTER": {
        cs: {
            zero: "",
            one: "za čtvrtletí",
            few: "za\xa0{{count}}\xa0čtvrtletí",
            other: "za\xa0{{count}}\xa0čtvrtletí",
        },
        en: "",
    },
    "medicalOperation.YEAR": {
        cs: {
            zero: "",
            one: "za rok",
            few: "za\xa0{{count}}\xa0roky",
            other: "za\xa0{{count}}\xa0let",
        },
        en: "",
    },
    "medicalOperation.LIVE_TIME": {
        cs: {
            one: "za život",
        },
        en: "",
    },
    "medicalReports.items": {
        cs: {
            zero: "položek",
            one: "položka",
            few: "položky",
            other: "položek",
        },
        en: "",
    },
    "paymentItems.next": {
        cs: {
            zero: "dalších",
            one: "další",
            few: "další",
            other: "dalších",
        },
        en: "",
    },
    "settings.profiles": {
        cs: {
            zero: "{{count}}\xa0uživatelů",
            one: "{{count}}\xa0uživatel",
            few: "{{count}}\xa0uživatelé",
            other: "{{count}}\xa0uživatelů",
        },
        en: "",
    },
    "settings.procedures": {
        cs: {
            zero: "{{count}}\xa0služeb",
            one: "{{count}}\xa0služba",
            few: "{{count}}\xa0služby",
            other: "{{count}}\xa0služeb",
        },
        en: "",
    },
    "settings.additionalProcedures": {
        cs: {
            zero: "{{count}}\xa0služeb/produktů",
            one: "{{count}}\xa0služba/produkt",
            few: "{{count}}\xa0služby/produkty",
            other: "{{count}}\xa0služeb/produktů",
        },
        en: "",
    },
    "settings.insuranceContracts": {
        cs: {
            zero: "{{count}}\xa0smluv",
            one: "{{count}}\xa0smlouva",
            few: "{{count}}\xa0smlouvy",
            other: "{{count}}\xa0smluv",
        },
        en: "",
    },
    "settings.ftVoucherApplicants": {
        cs: {
            zero: "{{count}}\xa0žadatelů",
            one: "{{count}}\xa0žadatel",
            few: "{{count}}\xa0žadatelé",
            other: "{{count}}\xa0žadatelů",
        },
        en: "",
    },
    "Invalid password": {
        cs: "Nesprávné heslo.",
        en: "Invalid password.",
    },
    "User not found": {
        cs: "Neznámý uživatelský e-mail.",
        en: "Invalid user e-mail.",
    },
    "Profile not allowed": {
        cs: "Profil neexistuje.",
        en: "Unknown user profile.",
    },
    "User has active profile in Medical Center": {
        cs: "Uživatel už byl přidán a je aktivní.",
        en: "User has active profile in Medical Center.",
    },
};

import {
    BookingRequestsTabName,
    CardFileTabName,
    EventCreateTabName,
    MedicalCenterSettingsSectionName,
    PersonalSettingsSectionName,
    SettingsTabsEnum,
    StatisticsTabName,
} from "@config/tabs";
import { CalendarViewType } from "@main-zone/components/calendar/types";
import { LoginPageQueryParams } from "@uxf/cms/pages/login-page";
import { createRouter } from "@uxf/router";

export type RouteList = {
    // main-zone
    "main-zone/index": {
        date?: string;
        staff?: number;
        view?: CalendarViewType;
    };
    "main-zone/event-create": {
        allDay?: "1";
        from?: string;
        patient?: number;
        procedure?: number;
        staff?: number;
        tab?: EventCreateTabName;
        to?: string;
        waitingPatient?: number;
    };
    "main-zone/event-update": { id: number };
    "main-zone/card-file": null;
    "main-zone/card-file-create": null;
    "main-zone/card-file-detail": {
        uuid: string;
        tab?: CardFileTabName;
        report?: string;
    };
    "main-zone/printable-invoice": { medicalReportUuid: string };
    "main-zone/printable-medical-reports": { uuid: string };
    "main-zone/printable-medical-report": { uuid: string };
    "main-zone/choose-profile": {
        redirect?: string;
    };
    "main-zone/create-password": { token: string };
    "main-zone/create-profile": { token?: string };
    "main-zone/employee-create-update": { id?: number };
    "main-zone/forgotten-password": null;
    "main-zone/login": LoginPageQueryParams;
    "main-zone/logout": null;
    "main-zone/payments": null;
    "main-zone/payments-export": null;
    "main-zone/registration": null;
    "main-zone/registration-email-sent": { email: string };
    "main-zone/renew-password": { token: string };
    "main-zone/settings": {
        tab: SettingsTabsEnum;
    };
    "main-zone/settings/medical-center": {
        section: MedicalCenterSettingsSectionName;
    };
    "main-zone/settings/personal": {
        section: PersonalSettingsSectionName;
    };
    "main-zone/statistics": {
        tab?: StatisticsTabName;
    };
    "main-zone/additional-procedure-create": null;
    "main-zone/additional-procedure-update": { id: number };
    "main-zone/ft-voucher-applicant-create": null;
    "main-zone/ft-voucher-applicant-update": { id: number };
    "main-zone/procedure-create": null;
    "main-zone/procedure-update": { id: number };
    "main-zone/sitemap-xml": null;
    "main-zone/token-check": { token: string };
    "main-zone/patient-diagram": { uuid: string; attendanceId: number };
    "main-zone/waiting-patients": null;
    "main-zone/waiting-patient-create": null;
    "main-zone/waiting-patient-update": { id: number };
    "main-zone/booking-requests": {
        tab?: BookingRequestsTabName;
        staff?: number;
    };
    "main-zone/insurance-companies-reports": null;
    "main-zone/batch-detail": { id: number };
};

export default createRouter<RouteList>({
    "main-zone/card-file": "/kartoteka",
    "main-zone/card-file-create": "/kartoteka/novy",
    "main-zone/card-file-detail": "/kartoteka/[uuid]",
    "main-zone/printable-invoice": "/kartoteka/faktura/[medicalReportUuid]",
    "main-zone/printable-medical-reports": "/kartoteka/[uuid]/tisk",
    "main-zone/printable-medical-report": "/kartoteka/zaznam/[uuid]",
    "main-zone/choose-profile": "/vyber-profilu",
    "main-zone/create-password": "/vytvoreni-hesla",
    "main-zone/create-profile": "/vytvoreni-profilu",
    "main-zone/employee-create-update": "/zamestnanec",
    "main-zone/forgotten-password": "/zapomenute-heslo",
    "main-zone/index": "/",
    "main-zone/event-create": "/nova-udalost",
    "main-zone/event-update": "/editace-udalosti/[id]",
    "main-zone/login": "/prihlaseni",
    "main-zone/logout": "/odhlaseni",
    "main-zone/payments": "/platby",
    "main-zone/payments-export": "/platby/export",
    "main-zone/registration": "/registrace",
    "main-zone/registration-email-sent": "/registracni-email",
    "main-zone/renew-password": "/obnova-hesla/[token]",
    "main-zone/settings": "/nastaveni/[tab]",
    "main-zone/settings/medical-center": "/nastaveni/zdravotnicke-zarizeni/[section]",
    "main-zone/settings/personal": "/nastaveni/osobni/[section]",
    "main-zone/statistics": "/statistiky",
    "main-zone/additional-procedure-create": "/nova-doplnkova-sluzba",
    "main-zone/additional-procedure-update": "/editace-doplnkove-sluzby/[id]",
    "main-zone/ft-voucher-applicant-create": "/novy-zadatel-ft-poukazu",
    "main-zone/ft-voucher-applicant-update": "/editace-zadatele-ft-poukazu/[id]",
    "main-zone/procedure-create": "/nova-sluzba",
    "main-zone/procedure-update": "/editace-sluzby",
    "main-zone/sitemap-xml": "/sitemap.xml",
    "main-zone/token-check": "/token-check/[token]",
    "main-zone/patient-diagram": "/patient-diagram/[uuid]/[attendanceId]/image.svg",
    "main-zone/waiting-patients": "/seznam-nahradniku",
    "main-zone/waiting-patient-create": "/novy-nahradnik",
    "main-zone/waiting-patient-update": "/editace-nahradnika/[id]",
    "main-zone/booking-requests": "/rezervace-ke-schvaleni",
    "main-zone/insurance-companies-reports": "/vyuctovani-pro-pojistovny",
    "main-zone/batch-detail": "/vyuctovani-pro-pojistovny/detail-davky/[id]",
});

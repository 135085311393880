// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS_VERSION = "926d56689553bff2b0752138a8f91516";

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 448, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "eye": { w: 576, h: 512 },
    "eye-slash": { w: 640, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 384, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "circle-info": { w: 512, h: 512 },
    "filter": { w: 512, h: 512 },
    "pen": { w: 512, h: 512 },
    "table": { w: 512, h: 512 },
    "file-arrow-down": { w: 384, h: 512 },
    "table-columns": { w: 640, h: 512 },
    "search": { w: 512, h: 512 },
    "file-download": { w: 384, h: 512 },
    "trash": { w: 448, h: 512 },
    "sort": { w: 320, h: 512 },
    "sort-up": { w: 320, h: 512 },
    "sort-down": { w: 320, h: 512 },
    "warning": { w: 512, h: 512 },
    "h1": { w: 576, h: 512 },
    "h2": { w: 640, h: 512 },
    "h3": { w: 640, h: 512 },
    "h4": { w: 640, h: 512 },
    "h5": { w: 640, h: 512 },
    "h6": { w: 640, h: 512 },
    "bold": { w: 384, h: 512 },
    "italic": { w: 384, h: 512 },
    "underline": { w: 448, h: 512 },
    "ol": { w: 512, h: 512 },
    "ul": { w: 576, h: 512 },
    "code-simple": { w: 576, h: 512 },
    "link": { w: 640, h: 512 },
    "image": { w: 512, h: 512 },
    "youtube": { w: 576, h: 512 },
    "block-quote": { w: 576, h: 512 },
    "arrow-turn-down-left": { w: 512, h: 512 },
    "arrow-turn-down-right": { w: 512, h: 512 },
    "rectangle-wide": { w: 640, h: 512 },
    "edit": { w: 512, h: 512 },
    "delete": { w: 448, h: 512 },
    "open-link-in-new-tab": { w: 512, h: 512 },
    "highlighter-line": { w: 576, h: 512 },
    "angleRight": { w: 256, h: 512 },
    "arrow-down-from-line": { w: 384, h: 512 },
    "arrow-up-to-line": { w: 384, h: 512 },
    "grip-dots-vertical": { w: 256, h: 512 },
    "key": { w: 512, h: 512 },
    "plus": { w: 448, h: 512 },
    "right-from-bracket": { w: 512, h: 512 },
    "address-card": { w: 576, h: 512 },
    "angle-right": { w: 20, h: 20 },
    "arrow-right-arrow-left": { w: 448, h: 512 },
    "ballot-check": { w: 512, h: 512 },
    "calendar-clock": { w: 576, h: 512 },
    "calendar-empty": { w: 448, h: 512 },
    "child-reacting": { w: 384, h: 512 },
    "circle-check": { w: 512, h: 512 },
    "circle-exclamation": { w: 512, h: 512 },
    "circle-question": { w: 512, h: 512 },
    "coins": { w: 512, h: 512 },
    "comments": { w: 640, h: 512 },
    "comment-dots": { w: 512, h: 512 },
    "chart-pie": { w: 576, h: 512 },
    "envelope": { w: 512, h: 512 },
    "file-circle-xmark": { w: 24, h: 21 },
    "file-lines": { w: 384, h: 512 },
    "files": { w: 448, h: 512 },
    "gear": { w: 512, h: 512 },
    "hourglass-start": { w: 384, h: 512 },
    "landmark": { w: 512, h: 512 },
    "minus": { w: 512, h: 448 },
    "money-bill": { w: 576, h: 512 },
    "octagon-xmark": { w: 20, h: 21 },
    "paperclip": { w: 448, h: 512 },
    "paper-plane-top": { w: 20, h: 20 },
    "phone": { w: 512, h: 512 },
    "repeat": { w: 512, h: 512 },
    "scribble-light": { w: 512, h: 512 },
    "scribble": { w: 512, h: 512 },
    "scribble-thick": { w: 512, h: 512 },
    "signature": { w: 640, h: 512 },
    "suitcase-medical": { w: 512, h: 512 },
    "timer": { w: 512, h: 512 },
    "tv": { w: 640, h: 512 },
    "warehouse": { w: 640, h: 512 },
    "faPro_regular.bell": { w: 448, h: 512 },
    "faPro_regular.calendar-check": { w: 448, h: 512 },
    "faPro_regular.chart-pie": { w: 576, h: 512 },
    "faPro_regular.file-invoice-dollar": { w: 384, h: 512 },
    "faPro_regular.file-signature": { w: 576, h: 512 },
    "faPro_regular.gears": { w: 640, h: 512 },
    "faPro_regular.hand-holding-medical": { w: 576, h: 512 },
    "faPro_regular.info": { w: 192, h: 512 },
    "faPro_regular.input-numeric": { w: 640, h: 512 },
    "faPro_regular.input-text": { w: 640, h: 512 },
    "faPro_regular.user": { w: 448, h: 512 },
    "faPro_solid.circle-question": { w: 512, h: 512 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "eye": true;
        "eye-slash": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "circle-info": true;
        "filter": true;
        "pen": true;
        "table": true;
        "file-arrow-down": true;
        "table-columns": true;
        "search": true;
        "file-download": true;
        "trash": true;
        "sort": true;
        "sort-up": true;
        "sort-down": true;
        "warning": true;
        "h1": true;
        "h2": true;
        "h3": true;
        "h4": true;
        "h5": true;
        "h6": true;
        "bold": true;
        "italic": true;
        "underline": true;
        "ol": true;
        "ul": true;
        "code-simple": true;
        "link": true;
        "image": true;
        "youtube": true;
        "block-quote": true;
        "arrow-turn-down-left": true;
        "arrow-turn-down-right": true;
        "rectangle-wide": true;
        "edit": true;
        "delete": true;
        "open-link-in-new-tab": true;
        "highlighter-line": true;
        "angleRight": true;
        "arrow-down-from-line": true;
        "arrow-up-to-line": true;
        "grip-dots-vertical": true;
        "key": true;
        "plus": true;
        "right-from-bracket": true;
        "address-card": true;
        "angle-right": true;
        "arrow-right-arrow-left": true;
        "ballot-check": true;
        "calendar-clock": true;
        "calendar-empty": true;
        "child-reacting": true;
        "circle-check": true;
        "circle-exclamation": true;
        "circle-question": true;
        "coins": true;
        "comments": true;
        "comment-dots": true;
        "chart-pie": true;
        "envelope": true;
        "file-circle-xmark": true;
        "file-lines": true;
        "files": true;
        "gear": true;
        "hourglass-start": true;
        "landmark": true;
        "minus": true;
        "money-bill": true;
        "octagon-xmark": true;
        "paperclip": true;
        "paper-plane-top": true;
        "phone": true;
        "repeat": true;
        "scribble-light": true;
        "scribble": true;
        "scribble-thick": true;
        "signature": true;
        "suitcase-medical": true;
        "timer": true;
        "tv": true;
        "warehouse": true;
        "faPro_regular.bell": true;
        "faPro_regular.calendar-check": true;
        "faPro_regular.chart-pie": true;
        "faPro_regular.file-invoice-dollar": true;
        "faPro_regular.file-signature": true;
        "faPro_regular.gears": true;
        "faPro_regular.hand-holding-medical": true;
        "faPro_regular.info": true;
        "faPro_regular.input-numeric": true;
        "faPro_regular.input-text": true;
        "faPro_regular.user": true;
        "faPro_solid.circle-question": true;
    }
}

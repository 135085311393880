import { createLocalize } from "@uxf/localize";
import cs from "@uxf/localize/locale/cs";

export const {
    LocalizeProvider,
    FormatDateTime,
    FormatMoney,
    FormatNumber,
    FormatPercentage,
    formatDateTime,
    formatMoney,
    formatNumber,
    formatPercentage,
    useFormatDateTime,
    useFormatMoney,
    useFormatNumber,
    useFormatPercentage,
    useLocaleConfig,
} = createLocalize({ cs });

export const config = {
    DEBUG: process.env.STAGE === "local",
    AUTHORIZATION_HEADER: process.env.AUTHORIZATION_HEADER || "X-Authorization",
    STAGE: process.env.STAGE as "local" | "dev" | "prod",
    FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
    BOOKING_URL: process.env.NEXT_PUBLIC_BOOKING_URL,
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
    NODE_ENV: process.env.NODE_ENV,
    PRIVACY_POLICY_URL: "https://www.rehatab.cz/ochrana-osobnich-udaju",
    TERMS_AND_CONDITIONS_URL: "https://www.rehatab.cz/obchodni-podminky",
    FEEDBACK_FORM_URL: "https://www.rehatab.cz/kontakt",
    HELP_URL: "https://www.rehatab.cz/napoveda",
    GDPR_URL: process.env.NEXT_PUBLIC_FRONTEND_URL + "/api/app/gdpr/printable-wording",
    IS_ALPHA_VERSION: true,
};

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { config } from "@config/config";
import merge from "deepmerge";
import isEqual from "lodash.isequal";
import { GetServerSidePropsContext, NextPageContext } from "next";
import { useMemo } from "react";

type NextContext = GetServerSidePropsContext | NextPageContext | null | undefined;

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<any> | null;

const uploadProgressLink = setContext((_, prevContext) => {
    return {
        ...prevContext,
        onProgress: prevContext.onProgress,
    };
});

const authLink = (ctx?: NextContext | null) =>
    setContext(() => {
        return {
            headers: {
                cookie: ctx?.req?.headers.cookie ?? null,
            },
        };
    });

function createApolloClient(ctx?: NextContext | null) {
    return new ApolloClient({
        ssrMode: typeof window === "undefined",
        link: ApolloLink.from([
            authLink(ctx),
            uploadProgressLink,
            new HttpLink({ uri: `${config.FRONTEND_URL}/graphql` }),
        ]),
        cache: new InMemoryCache(),
    });
}

export async function clearCache() {
    await apolloClient?.clearStore();
}

export function initializeApollo(ctx: NextContext | null = null, initialState: any = null) {
    const _apolloClient = apolloClient ?? createApolloClient(ctx);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
        const data = merge(existingCache, initialState, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
            ],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") {
        return _apolloClient;
    }
    // Create the Apollo Client once in the client
    if (!apolloClient) {
        apolloClient = _apolloClient;
    }

    return _apolloClient;
}

export type GetStaticPropsResult<P> = { props: P; revalidate?: number | boolean };

export function addApolloState<P>(
    client: ApolloClient<any>,
    pageProps: GetStaticPropsResult<P>,
): GetStaticPropsResult<P> {
    if (pageProps.props !== undefined) {
        (pageProps.props as any)[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps: any) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initializeApollo(null, state), [state]);
}

import { ApolloProvider } from "@apollo/client";
import { route, useActiveRoute } from "@app-routes";
import { config } from "@config/config";
import { ICONS, ICONS_VERSION } from "@config/icons";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { LocalizeProvider } from "@localize";
import { useApollo } from "@shared/lib/Apollo/apollo-client";
import { ErrorService } from "@shared/services/error-service";
import "@ui/styles/globals.css";
import { FileResponse } from "@uxf/cms/api";
import { config as cmsConfig, container } from "@uxf/cms/config";
import { UiContextProvider as UiCmsContextProvider } from "@uxf/cms/ui";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { FlashMessages, getFlashMessagesRef } from "@uxf/ui/flash-messages";
import { Modal } from "@uxf/ui/modal/modal";
import { getModalRef } from "@uxf/ui/modal/modal-service";
import axios from "axios";
import { locale } from "dayjs";
import "dayjs/locale/cs";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";

locale("cs");

cmsConfig.set("api-url", process.env.NEXT_PUBLIC_FRONTEND_URL ?? "").set("app-name", "RehaTab");

container
    .register("api.uploadFile", async (file, namespace) => {
        // TODO move to cms?
        const formData = new FormData();
        formData.append("files[]", file);

        const response = await axios.post<FileResponse[]>("/api/cms/files/upload", formData, {
            timeout: 100 * 1000,
            params: { namespace: namespace ?? "default" },
        });

        if (response.data.length > 0) {
            return response.data[0];
        }

        throw new Error("Response is null");
    })
    .register("service.error", ErrorService)
    .register("route", route as any)
    .register("useActiveRoute", useActiveRoute)
    .register("useLoggedUser", () => ({ roles: [] })); // TODO

const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    domain: config.FRONTEND_URL ?? "",
    icon: { spriteFilePath: `/icons-generated/_icon-sprite.svg?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};

const fontInter = Inter({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-inter",
    weight: ["400", "500", "600"],
    display: "swap",
});

function UxfApp(props: AppProps) {
    const apollo = useApollo(props.pageProps);

    return (
        <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>
                {`
                    :root {
                        --font-inter: ${fontInter.style.fontFamily};
                    }
                `}
            </style>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Head>
            <ApolloProvider client={apollo}>
                <LocalizeProvider value="cs">
                    {/*TODO: @kubaH - hack se zdvojeným providerem*/}
                    <UiContextProvider value={UI_PROVIDER_CONFIG}>
                        <UiCmsContextProvider value={UI_PROVIDER_CONFIG}>
                            <props.Component {...props.pageProps} />
                            <Modal ref={getModalRef()} />
                            <FlashMessages ref={getFlashMessagesRef()} />
                        </UiCmsContextProvider>
                    </UiContextProvider>
                </LocalizeProvider>
            </ApolloProvider>
        </>
    );
}

export default UxfApp;
